  import React  from 'react'
  import { useParams } from 'react-router-dom'
  import Layout from '../components/Layout'

  export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement("script");
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }

  const QRPage = () => {

      const { id } = useParams();
      
      function makeCode () {
        var qrcode = new window.QRCode(document.getElementById("qrcode"), {
          width : 300,
          height : 300
        });

        var elText = 'testing1234';
        
        if (elText.length<=0) {
          return;
        }
        
        qrcode.makeCode(elText);
      }

  // Create the function

    return (
        <Layout>
          <form className='w-full max-w-sm flex flex-col space-y-6'>
              <div className='flex flex-col items-center mb-6 space-y-6'>
              <label
                      className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
                      htmlFor='username'
                  >
                      {id}
                  </label>
                  <div id="qrcode" style={{'width':'100px','height':'100px'}}></div>
                  { makeCode() }
              </div>
          </form>
        </Layout>
    )
  }

  export default QRPage
