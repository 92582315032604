import React, { useState } from 'react'
import { useNavigate , useParams } from 'react-router-dom'
import Layout from '../components/Layout'

const ApiPre="https://i";

const Registration = () => {

    const navigate = useNavigate();
    const [name, setName] = useState("");
    
    const { id } = useParams();

    function handleSubmit() {
        if(id) {
            var ApiServer= ApiPre+window.location.hostname.substring(window.location.hostname.indexOf("."));
            var BotPath = ApiServer+'/TeleBot';
            var url = BotPath + `?start=TAGNAME=${name}=TAGRegID=${id}`;
            window.location.href = url;
            navigate(`/registration/${id}`);
        }
    }

  return (
      <Layout>
        <form className='w-full max-w-sm flex flex-col space-y-6'>
            <div className='flex flex-col items-center mb-6 space-y-6'>
            <label
                    className='block text-gray-600 font-bold text-1xl md:text-center mb-1 md:mb-0 pr-4'
                >
                    You have got a new QR tag!
            </label>
            </div>
            <div className='flex flex-col items-center mb-6 space-y-2'>
                <label
                    className='block text-gray-400 text-left mb-1 md:mb-0 pr-4'
                >
                    Input the name for the tag below:
                </label>
                    <input value={name} type="text" id="qr_name" maxLength="20"
                    onChange={(e) =>{
                        const { value, maxLength } = e.target;
                        var tmpname=value.trim();
                        if (tmpname.length>0) {
                            tmpname=tmpname.match(/([0-9a-zA-Z_-])/g);
                            if (tmpname && tmpname.length>0) {
                                tmpname = tmpname.join("");
                                tmpname = tmpname.slice(0, maxLength);
                            } else {
                                tmpname = "";
                            }
                        }
                        setName(tmpname);
                        } }  onKeyDown={(e) => {if (e.key === 'Enter'){ handleSubmit() }  }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="MyFancyLaptop" required  />
            </div>
            <div className='md:flex md:items-center'>
                <button
                    className='self-center w-full shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded'
                    type='button'
                    onClick={handleSubmit}
                >
                    Tag To Me via Telegram
                </button>
            </div>
        </form>
      </Layout>
  )
}


export default Registration
