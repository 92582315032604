import React, { useEffect, useRef, useState } from 'react'
//import Layout from '../components/Layout'
//import { useParams } from 'react-router-dom'
import sendIcon from '../components/sendIcon'
import { useLocation } from 'react-router-dom';
var CryptoJS = require("crypto-js");

var randomuuid = undefined;
const WSchat="wss://chat";
var WSSERVER=undefined;
var wspubkey = undefined;

const ChatPage = () => {

    const [ messages, setMessages] = useState([]);
    const [ isConnectionOpen, setConnectionOpen] = useState(false);
    const [ messageBody, setMessageBody] = useState("");
    const [ reconn, setReconn] = useState(false);
    const [ retry, setRetry] = useState(0);
    const [ chatroomname, setChatroomname] = useState("");
    const [ roomname, setRoomname] = useState("someone");
    const [ rsaKey, setRsaKey] = useState();
    const [ rsaWip, setRsaWip] = useState(false);
    const [ rsapubKey, setRsapubKey] = useState();
    const [ randomKey, setRandomKey] = useState();
    const [ visibilityState, setVisibilityState] = useState(true);
    //const { username } = useParams();
    //const { room } = useParams();
    const ws = useRef();
    const location = useLocation();
    const room = new URLSearchParams(location.search).get('id');
    WSSERVER= WSchat+window.location.hostname.substring(window.location.hostname.indexOf("."));
    if (process.env.REACT_APP_WSS) WSSERVER = process.env.REACT_APP_WSS
    if (room !== undefined ) {
      randomuuid = room.substring(0,10);
    }
    else
      console.log("Missing id");

    const checkconn = () => {
      if (!isConnectionOpen && !reconn) {
        setRetry(Date.now()+1);
      }
    }

    const sendMessage = () => {
        if(messageBody && isConnectionOpen) {
            var encdata =   MsgEncrypter.encrypt(randomKey,messageBody);
            ws.current.send(
                JSON.stringify({
                    roomid : room,
                    uuid: randomuuid,
                    //sender: username,
                    body: encdata,
                    enc: true
                })
            );
            //if (messageBody==="CLOSE") { ws.current.close();  }
            setMessageBody("");
        }
    };

    const generateRSA = async () => {
      if (rsaKey!==undefined) return;
      if (rsaWip) return;
      setRsaWip(true);
      await window.crypto.subtle.generateKey(
        {
          name: "RSA-OAEP",
          modulusLength: 2048,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: "SHA-256",
        },
        true,
        ["encrypt", "decrypt"],
      ).then(function(keyPair) {
        window.crypto.subtle.exportKey(
            "spki",
            keyPair.publicKey
        ).then(function(ex_pubkey) {

          const exportedAsString = ab2str(ex_pubkey);
          wspubkey = window.btoa(exportedAsString);
          setRsaWip(false);
          setRsapubKey(wspubkey);
          setRsaKey(keyPair.privateKey);
          setRetry(retry+1);
        }).catch(function(err) {
            console.log(err);
        });
      });
    
    }

    useEffect(() => {
        if (rsaKey===undefined) {
          generateRSA();
          return;
        }
        setReconn(true);
        console.log("Trying connection");
        ws.current = new WebSocket(WSSERVER+'/?roomid='+room);
        ws.current.onopen = () => {
            console.log("Connection Opened");
            setConnectionOpen(true);
            setReconn(false);
            ws.current.send(
              JSON.stringify({
                  roomid : room,
                  uuid: randomuuid,
                  //sender: username,
                  body: undefined,
                  pubkey : rsapubKey,
                  msgslen : ( messages ? messages.length : 0 )
              })
            );
          }
          ws.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.roomname && data.roomname!==roomname && data.uuid === randomuuid) {
            setRoomname(data.roomname);
          }
          if (data.chatroomname)
            setChatroomname(data.chatroomname);

          if ( data.deckey ){
            const bstr = window.atob(data.deckey);
            const abstr = str2ab(bstr);
            window.crypto.subtle.decrypt( { name: "RSA-OAEP" }, rsaKey, abstr).then( function(result){
              const dec = new TextDecoder();
              var rankey = dec.decode(result);
              setRandomKey(rankey);
            }).catch( (e)=> {
              console.log(e)
            });
          }
          else {
            setMessages((_messages) => [..._messages, data]);
          }
  
          //messages.forEach(message => {
          //  if (message.state === 0 && message.uuid !== randomuuid ) setOtherstate(message.state)
          //    else setOtherstate(1)
          //});
        };
        ws.current.onclose  = (event) => {
          console.log("Connection Closed");
          setConnectionOpen(false);
          setReconn(false);
          //delayreconn()    
        };
        return () => {
            console.log("Cleaning up...");
            setConnectionOpen(false);
            setReconn(false);
            ws.current.close();
        }
      //}, [rsaKey,rsapubKey,randomKey,retry,room,roomname]);
      }, [retry]); // eslint-disable-line react-hooks/exhaustive-deps

    const scrollTarget = useRef(null);

    useEffect(() => {
        if(scrollTarget.current) {
            scrollTarget.current.scrollIntoView({behavior: "smooth"});
        }
    }, [messages.length]);
    
    const handleVisibilityChange  = () => {
      setVisibilityState(document.visibilityState === 'visible');
      checkconn();
    };

    useEffect(() => {
      document.addEventListener("visibilitychange", handleVisibilityChange)
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange)
      }
    }, []);

  return (
    
    <div className='w-full h-[calc(100dvh) flex flex-col items-center'>
      <header className='w-full flex flex-col justify-start items-center'>
        <h2 className='text-3xl font-bold'>{ chatroomname }</h2> 
      </header>
      <main id="chat-view-container" className="flex flex-col w-full flex-1 justify-end overflow-y-auto p-3">
      {messages.map((message, index) => (
        <div key={index} className={`my-1 rounded py-1 w-1/3 text-white ${
          message.uuid === randomuuid ? "self-end bg-purple-600" : "bg-blue-600"
        } `}
        style={{display: message.body ? 'block' : 'none' }}
        >
          <div className="flex items-center">
            <div className="ml-2">
              <div className="flex flex-row">
                <div className="text-sm font-medium leading-5 text-gray-900">
                  {message.system ? "[SYSTEM]" : message.roomname } at
                </div>
                <div className="ml-1">
                  <div className="text-sm font-bold leading-5 text-gray-900">
                    { new Date(message.sentts).toLocaleTimeString("en-US", {timeStyle: "short"})}{" "}
                  </div>
                </div>
              </div>
              <div className="mt-1 text-sm font-semibold leading-5">
                { message.enc ? MsgEncrypter.decrypt(randomKey,message.body) : message.body }
              </div>
            </div>
          </div>
        </div>
      ))}
      <div ref={scrollTarget} />
    </main>
    <footer className="w-full justify-end">
        {
          (isConnectionOpen)?
          <p> You are online as <span className="font-bold">{roomname}</span></p>
          :<p> [Offline] Send a message to stay online</p>
        }
      <div className="flex flex-row">
        <input
          id="message"
          type="text"
          className="w-full border-2 border-gray-200 focus:outline-none rounded-md p-2 hover:border-purple-400"
          placeholder="Type your message here..."
          value={messageBody}
          onChange={(e) => {
            if (e.target.value.length>100)
              e.target.value=e.target.value.substring(0,100)
            setMessageBody(e.target.value)
          }
          }
          onKeyDown={(e) => {if (e.key === 'Enter'){ sendMessage()} else {checkconn()} }}
          required
        />
        <button
          aria-label="Send"
          onClick={sendMessage}
          className="m-3"
          //disabled={!isConnectionOpen}
        >
          {sendIcon}
        </button>
      </div>
    </footer>
    </div>
  )
}

function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export class MsgEncrypter {
  static encrypt(randomkey,clearText) {
    try {
      let iv = CryptoJS.lib.WordArray.random(128/8);
      let message = CryptoJS.AES.encrypt(clearText, CryptoJS.enc.Hex.parse(randomkey),{iv: iv});
      let enc2 = CryptoJS.enc.Hex.parse(message.iv+message.ciphertext);
      let encb64 = window.btoa(enc2);
      return encb64;

    } catch (error) {
      console.log("encrypt error", error)
      return null;
    }
  }

  static decrypt(randomkey,encryptedText) {
    try {
      let encstr = window.atob(encryptedText);
      let deciv = CryptoJS.enc.Hex.parse(encstr.substring(0,128/8*2));
      let decmsg = CryptoJS.enc.Hex.parse(encstr.substring(128/8*2));
      let cipherMsg = CryptoJS.lib.CipherParams.create({ ciphertext: decmsg });
      let bytes  = CryptoJS.AES.decrypt(cipherMsg, CryptoJS.enc.Hex.parse(randomkey),{iv:deciv});
      let oriMsg = bytes.toString(CryptoJS.enc.Utf8);
      return oriMsg;
    } catch (error) {
      console.log("decrypt error", error)
      return null;
    }
  }
}

export default ChatPage
