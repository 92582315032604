import React from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LoginPage from "./pages/LoginPage";
import ChatPage from "./pages/ChatPage";
import Registration from "./pages/Registration";
import QRPage from "./pages/QRPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/chat/" element={<ChatPage />} />
        <Route path="/registration/:id" element={<Registration />} />
        <Route path="/QRPage/:id" element={<QRPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App