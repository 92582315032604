import React from 'react'
//import { useNavigate  } from 'react-router-dom'
import Layout from '../components/Layout'

const ApiPre="https://i";

const LoginPage = () => {

    function handleSubmit() {
        var ApiServer= ApiPre+window.location.hostname.substring(window.location.hostname.indexOf("."));
        var BotPath = ApiServer+'/TeleBot';
        var url = BotPath;
        
        window.location.href = url;
        //navigate(`/registration/${id}`);
    }

    return (
      <Layout>
        <form className='w-full max-w-sm flex flex-col space-y-6'>

            <div className='flex flex-col items-center mb-6 space-y-6'>
                <label
                    className='block text-gray-600 text-xl font-bold text-center mb-1 md:mb-0 pr-4'
                >
                    Keep privacy, remain contactable
                </label>
            </div>

            <div className='flex flex-col items-center mb-6 space-y-6'>
                <label
                    className='block text-gray-400 font-bold text-center mb-1 md:mb-0 pr-4'
                >
                    TagTo.Me use QR code to contact owner, without any privacy information from both side.
                </label>
            </div>

            <div className='flex flex-col items-center mb-6 space-y-6'>
                <label
                    className='block text-gray-400 font-bold text-center mb-1 md:mb-0 pr-4'
                >
                    No registration. No app required.
                </label>
            </div>

            <div className='md:flex md:items-center'>
                <button
                    className='self-center w-full shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded'
                    type='button'
                    onClick={handleSubmit}
                >
                    Get free TagToMe QR tag via Telegram
                </button>
            </div>
        </form>
      </Layout>
  )
}


export default LoginPage
